import Address from "../dto/Address";
import Organisation from "../dto/Organisation";
import Employee from "../dto/Employee";
import User from "../dto/User";
import Alert from "../dto/Alert";
import AlertTemplate from "../dto/AlertTemplate";
import NotificationMsg from "../dto/NotificationMsg";

class BackendHelper {
  private baseURL = process.env.REACT_APP_BACKEND_API_URL;

  public async authenticate(email: string, password: string): Promise<string> {
    const response = await fetch(`${this.baseURL}/auth`, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa(`${email}:${password}`),
      },
    });
    if (!response.ok) {
      throw new Error(`Status code = ${response.status}`);
    }
    const data = await response.text();
    return data;
  }

  public async userInfo(): Promise<User> {
    const response = await fetch(`${this.baseURL}/user/info`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    });
    if (!response.ok) {
      throw new Error(`Status code = ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  public async registerOrganisation(data: Organisation): Promise<any> {
    const response = await fetch(`${this.baseURL}/public`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 409) {
      throw new Error("Email address is already in use");
    } else if (!response.ok) {
      throw new Error(`Status code = ${response.status}`);
    }
    return response;
  }

  public async getOrganisation(): Promise<Organisation> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}`,
      null
    );
    return response.json();
  }

  public async updateOrganisation(data: Organisation): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}`,
      data
    );
    return response;
  }

  public async getOrganisationAddress(): Promise<Address> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/address`,
      null
    );
    return response.json();
  }

  public async updateOrganisationAddress(data: Address): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}/address`,
      data
    );
    return response;
  }

  public async createEmployee(data: Employee): Promise<Employee> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/employee`,
      data
    );
    return response.json();
  }

  public async getEmployees(): Promise<Employee[]> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee`,
      null
    );
    return response.json();
  }

  public async getEmployee(employeeId: number): Promise<Employee> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}`,
      null
    );
    return response.json();
  }

  public async updateEmployee(data: Employee, employeeId?: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${data.id}`,
      data
    );
    return response;
  }

  public async deleteEmployee(employeeId: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "DELETE",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}`,
      null
    );
    return response;
  }

  public async getEmployeeAddress(employeeId: number): Promise<Address> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/address`,
      null
    );
    return response.json();
  }

  public async updateEmployeeAddress(
    employeeId: number,
    data: Address
  ): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/address`,
      data
    );
    return response;
  }

  public async getProfilePicture(employeeId: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/photo`,
      null
    );
    return response;
  }

  public async uploadProfilePicture(employeeId: number, file: File): Promise<any> {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(
    `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/photo`,
    {
      method: 'POST',
      body: formData,
      headers: {
        //Let browser configure
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

  public async getDocument(employeeId: number): Promise<Address> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/contract`,
      null
    );
    return response;
  }

  public async uploadDocument(employeeId: any, data: any): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/contract`,
      data
    );
    return response;
  }

  
  public async sendNotifications(data: NotificationMsg): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/notification`,
      data
    );
    return response;
  }

  public async sendNotification(employeeId: number, data: NotificationMsg): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${employeeId}/notification`,
      data
    );
    return response;
  }

  public async getNotifications(employeeId: number): Promise<NotificationMsg[]> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/employee/${user?.employeeId}/notification`,
      null
    );
    return response.json();
  }


  public async createAlertTemplate(data: AlertTemplate): Promise<AlertTemplate> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/template`,
      data
    );
    return response.json();
  }

  public async getAlertTemplates(): Promise<AlertTemplate[]> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/template`,
      null
    );
    return response.json();
  }

  public async getAlertTemplate(alertTemplateId: number): Promise<AlertTemplate> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/template/${alertTemplateId}`,
      null
    );
    return response.json();
  }

  public async updateAlertTemplate(data: AlertTemplate, alertTemplateId?: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/template/${data.id}`,
      data
    );
    return response;
  }

  public async deleteAlertTemplate(alertTemplateId: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "DELETE",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/template/${alertTemplateId}`,
      null
    );
    return response;
  }

  public async createAlert(data: Alert): Promise<Alert> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "POST",
      `${this.baseURL}/organisation/${user?.organisationId}/alert`,
      data
    );
    return response.json();
  }

  public async getAlerts(): Promise<Alert[]> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/alert`,
      null
    );
    return response.json();
  }

  public async getAlert(alertId: number): Promise<Alert> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "GET",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/${alertId}`,
      null
    );
    return response.json();
  }

  public async updateAlert(data: Alert, alertId?: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "PUT",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/${data.id}`,
      data
    );
    return response;
  }

  public async deleteAlert(alertId: number): Promise<any> {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const response = await this.fetchMethod(
      "DELETE",
      `${this.baseURL}/organisation/${user?.organisationId}/alert/${alertId}`,
      null
    );
    return response;
  }

  private async fetchMethod(
    method: string,
    url: string,
    data: any,
    additionalData?: any
  ): Promise<any> {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
      body: data !== null ? JSON.stringify(data) : null,
    });
    if (!response.ok) {
      throw new Error(`Status code = ${response.status}`);
    }
    return response;
  }
}

export default BackendHelper;
/* function setItems(response: any) {
  throw new Error("Function not implemented.");
}
 */
