import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../types/AuthContext";
import BackendHelper from "../api/helpers/BackendHelper";
import User from "../api/dto/User";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login, saveAuthToken, saveUser } = useAuth();
  const backend = new BackendHelper();
  const navigate = useNavigate();

  const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setError("");
    if (email === "" || password === "") {
      setError("All fields are required");
      return;
    }
    if (!regex.test(email)) {
      setError("Enter valid email");
      return;
    }

    // login
    await backend
      .authenticate(email, password)
      .then((token) => {
        saveAuthToken(token);
      })
      .catch((e) => {
        setError("Invalid email or password " + e);
        return;
      });

    // get user info
    await backend
      .userInfo()
      .then((user: User) => {
        saveUser(user);
        login(user.type);
        if (user.type === "organisation") {
          navigate("/organisation-profile");
        } else if (user.type === "employee") {
          navigate("/employee-profile");
        }
      })
      .catch((e) => {
        setError("Cannot get user info " + e);
        return;
      });
  };

  const handleRegorgClick = () => {
    navigate("/register-organisation");
  };

  return (
    <>
      <div className="login">
        <img
          src={"./initiator-logo.jpeg"}
          alt=""
          width="150"
          height="150"
          className="login-img"
        />
        <h3>Welcome to Initiator</h3>
        <form>
          <h5>Email address</h5>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              className="login-text-input"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <h5>Password</h5>
          <div>
            <input
              type="password"
              id="password"
              name="password"
              className="login-text-input"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && (
            <div style={{ color: "red" }}>
              <span>{error}</span>
            </div>
          )}
          <div>
            <input
              type="submit"
              value="LOGIN"
              className="login-btn"
              onClick={handleLogin}
            />
            <input
              type="submit"
              value="Register new organisation"
              className="subcat-btn"
              onClick={handleRegorgClick}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
