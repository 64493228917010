import React, { useState, useEffect } from "react";
import Select, { SingleValue, MultiValue } from "react-select";
import NewTemplateModal from "./NewTemplateModal";
import BackendHelper from "../api/helpers/BackendHelper";
import SparseEmployee from "../api/dto/SparseEmployee";

type OptionType = {
  id: number | undefined;
  value: string;
  label: string;
  subject?: string;
  message?: string;
  answerRequired?: boolean;
};

type CreateAlertProps = {
  onSave: (
    templateId: number,
    alertDays: number,
    reminderDays: number,
    recipients: SparseEmployee[],
    answerRequired: boolean
  ) => void;
  initialData?: {
    templateId: number;
    alertDays: number;
    reminderDays: number;
    recipients: SparseEmployee[];
    answerRequired: boolean;
  } | null;
  onCancel: () => void;
  templates: OptionType[];
  addNewTemplate: (
    templateName: string,
    templateSubject: string,
    templateMessage: string
  ) => void;
};

const CreateAlert: React.FC<CreateAlertProps> = ({
  onSave,
  initialData,
  onCancel,
  templates,
  addNewTemplate,
}) => {
  const [alertDays, setAlertDays] = useState(initialData?.alertDays || 0);
  const [reminderDays, setReminderDays] = useState(
    initialData?.reminderDays || 0
  );
  const [selectedTemplate, setSelectedTemplate] = useState<
    SingleValue<OptionType>
  >(
    initialData
      ? {
          id: initialData.templateId,
          value: "edit-template",
          label: "Edit current template",
        }
      : null
  );
  const [selectedTemplateReminder, setSelectedTemplateReminder] = useState<
    SingleValue<OptionType>
  >(
    initialData
      ? {
          id: initialData.templateId,
          value: "edit-template",
          label: "Edit current template for reminder",
        }
      : null
  );
  const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);
  const [employees, setEmployees] = useState<OptionType[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<
    MultiValue<OptionType>
  >([]);
  const [templateId, setTemplateId] = useState(
    initialData?.templateId || 0
  );
  const [templateReminderId, setTemplateReminderId] = useState(
    initialData?.templateId || 0
  );
  /*
  const [templateSubject, setTemplateSubject] = useState(
    initialData?.subject || ""
  );
  const [templateMessage, setTemplateMessage] = useState(
    initialData?.message || ""
  );
  */
  const [answerRequired, setAnswerRequired] = useState(
    initialData?.answerRequired || false
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const loadEmployees = async () => {
      try {
        const backend = new BackendHelper();
        const employeeList: SparseEmployee[] = await backend.getEmployees();
        const employeeOptions = employeeList.flatMap((employee) => {
          if (employee.id === null) {
            console.error("Employee ID is null:", employee);
            return [];
          }
          return [{
            id: employee.id,
            value: employee.id.toString(),
            label: employee.firstName + " " + employee.lastName,
            firstName: employee.firstName,
            lastName: employee.lastName,
          }];
        });
        setEmployees(employeeOptions);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    loadEmployees();
  }, []);

  useEffect(() => {
    if (initialData) {
      const recipientsArray = initialData.recipients
        .map((recipient) => ({
          id: recipient.id !== null ? recipient.id : undefined,
          value: recipient.firstName + " " + recipient.lastName, 
          label: recipient.id !== null ? `${recipient.id}: ${recipient.firstName} ${recipient.lastName}` : `${recipient.firstName} ${recipient.lastName}`,
        }))
        .filter((recipient) => recipient.id !== undefined); 
      setSelectedRecipients(recipientsArray as MultiValue<OptionType>);
      setTemplateId(initialData.templateId);
      setAlertDays(initialData.alertDays);
      setReminderDays(initialData.reminderDays);
      setAnswerRequired(initialData.answerRequired || false);
    }
  }, [initialData]);

  const handleCreateNewTemplate = () => {
    setIsNewTemplateModalOpen(true);
  };

  const handleSaveTemplate = (
    templateName: string,
    templateSubject: string,
    templateMessage: string
  ) => {
    addNewTemplate(
      templateName,
      templateSubject,
      templateMessage
    );
    setIsNewTemplateModalOpen(false);
  };

  const handleTemplateChange = (selectedOption: SingleValue<OptionType>) => {
    if (selectedOption?.value === "new-template") {
      handleCreateNewTemplate();
    } else {
      setSelectedTemplate(selectedOption);
      setTemplateId(selectedOption?.id || 0);
    }
  };

  const handleTemplateReminderChange = (selectedOption: SingleValue<OptionType>) => {
    if (selectedOption?.value === "new-template") {
      handleCreateNewTemplate();
    } else {
      setSelectedTemplateReminder(selectedOption);
      setTemplateReminderId(selectedOption?.id || 0);
    }
  };

  const handleRecipientChange = (selectedOptions: MultiValue<OptionType>) => {
    const selectAllOption = selectedOptions.find(
      (option) => option.value === "all-employees"
    );

    if (selectAllOption) {
      setSelectedRecipients(employees);
    } else {
      setSelectedRecipients(selectedOptions);
    }
  };

  const handleCreatedAlert = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!alertDays || selectedRecipients.length === 0) {
      console.log("Validation failed");
      return;
    }

    const recipientEmployees = selectedRecipients.map((recipient) => {
      const employee = employees.find(emp => emp.value === recipient.value) as SparseEmployee | undefined;
      return employee ? { id: employee.id, firstName: employee.firstName, lastName: employee.lastName } : null;
    }).filter(emp => emp !== null) as SparseEmployee[];

    onSave(
      templateId,
      alertDays,
      reminderDays,
      recipientEmployees,
      answerRequired
    );
  };

  const handleCancel = () => {
    setAlertDays(0);
    setReminderDays(0);
    setSelectedTemplate(null);
    setSelectedRecipients([]);
    setAnswerRequired(false);
    setIsSubmitted(false);
    onCancel();
  };

  return (
    <div className="create-new-alert">
      <h2>{initialData ? "Edit alert" : "Create new alert"}</h2>
      <form className="create-new-alert-form" onSubmit={handleCreatedAlert}>
        <div className="new-alert-input">
          <Select
            value={selectedTemplate}
            onChange={handleTemplateChange}
            options={templates}
            className="template-selector"
            placeholder="Select message template"
          />
          {isSubmitted && !selectedTemplate && (
            <div className="error-message">Please select a template.</div>
          )}
        </div>

        <div className="new-alert-input">
          <Select
            value={selectedTemplateReminder}
            onChange={handleTemplateReminderChange}
            options={templates}
            className="template-selector"
            placeholder="Select message template for reminder"
          />
          {isSubmitted && !selectedTemplateReminder && (
            <div className="error-message">Please select a template.</div>
          )}
        </div>

        <div className="new-alert-input-checkbox">
          <label>
            Answer required
            <input
              type="checkbox"
              checked={answerRequired}
              onChange={(e) => setAnswerRequired(e.target.checked)}
            />
          </label>
        </div>

        <div className="new-alert-input">
          <Select
            isMulti
            value={selectedRecipients}
            onChange={handleRecipientChange}
            options={[
              { id:0, value: "all-employees", label: "All Employees" }, // FIXIT
              ...employees,
            ]}
            className="recipients-selector"
            placeholder="Select recipients"
          />
          {isSubmitted && selectedRecipients.length === 0 && (
            <div className="error-message">
              Please select at least one recipient.
            </div>
          )}
        </div>

        <div className="alert-days">
          <h5>Send alert</h5>
          <div className="number-area">
            <input
              type="number"
              min="1"
              id="alert-days"
              name="alert-days"
              className="number-text-input"
              value={alertDays}
              onChange={(e) => setAlertDays(parseInt(e.target.value))}
            />
          </div>
          <h5>days before end of contract</h5>
          {isSubmitted && !alertDays && (
            <div className="error-message">This field is required.</div>
          )}
        </div>
        <div className="reminder-days">
          <h5>Send reminder</h5>
          <div className="number-area">
            <input
              type="number"
              min="1"
              id="reminder-days"
              name="reminder-days"
              className="number-text-input"
              value={reminderDays}
              onChange={(e) => setReminderDays(parseInt(e.target.value))}
            />
          </div>
          <h5>days before end of contract</h5>
        </div>
        <div className="save-cancel-btns">
          <button type="submit" className="save-cancel-btn">
            Save and close
          </button>
          <button
            type="button"
            className="save-cancel-btn"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
      <NewTemplateModal
        isOpen={isNewTemplateModalOpen}
        onClose={() => setIsNewTemplateModalOpen(false)}
        onSave={handleSaveTemplate}
      />
    </div>
  );
};

export default CreateAlert;
