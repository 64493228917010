import React from "react";

type ConfirmationPopupProps = {
  message: string;
  onClose: () => void;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  message,
  onClose,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <h6>{message}</h6>
          <button className="save-cancel-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
