import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import CreateAlert from "../components/CreateAlert";
import NewTemplateModal from "../components/NewTemplateModal";
import { useAuth } from "../types/AuthContext";
import BackendHelper from "../api/helpers/BackendHelper";
import AlertTemplate from "../api/dto/AlertTemplate";
import Alert from "../api/dto/Alert";
import Employee from "../api/dto/Employee";
import SparseEmployee from "../api/dto/SparseEmployee";

type AlertType = {
  id: number | undefined;
  templateId: number;
  alertDays: number;
  reminderDays: number;
  recipients: SparseEmployee[];
  answerRequired: boolean;
};

type TemplateType = {
  id: number | undefined;
  value: string;
  label: string;
  subject: string;
  message: string;
};

const Alerts: React.FC = () => {
  const { isOrganisation } = useAuth();
  const backend = new BackendHelper();
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [selectedAlert, setSelectedAlert] = useState<AlertType | null>(null);
  const [templates, setTemplates] = useState<TemplateType[]>([]);

  const openAlertsModal = () => {
    setShowAlertsModal(true);
  };

  const openTemplateModal = () => {
    setShowTemplateModal(true);
  };

  const handleCloseModal = () => {
    setShowAlertsModal(false);
    setShowTemplateModal(false);
    setSelectedAlert(null);
  };

  const saveAlert = (
    templateId: number,
    alertDays: number,
    reminderDays: number,
    recipients: SparseEmployee[],
    answerRequired: boolean
  ) => {

    if (selectedAlert) {
      backend.updateAlert({
        id: selectedAlert.id,
        alertTemplateId: templateId,
        alertTemplateReminderId: templateId,
        actionRequired: answerRequired,
        alertDays: alertDays,
        alertDaysReminder: reminderDays,
        targets: recipients
      });

      const updatedAlerts = alerts.map((alert) =>
        alert.id === selectedAlert.id
          ? { ...alert, alertDays, reminderDays, recipients }
          : alert
      );
      setAlerts(updatedAlerts);
      setSelectedAlert(null);
    } else {
      const newAlert: AlertType = {
        id: alerts.length + 1,
        templateId,
        alertDays,
        reminderDays,
        recipients,
        answerRequired,
      };
      backend.createAlert({
        id: undefined,
        alertTemplateId: templateId,
        alertTemplateReminderId: templateId,
        actionRequired: answerRequired,
        alertDays: alertDays,
        alertDaysReminder: reminderDays,
        targets: recipients
      });
      setAlerts([...alerts, newAlert]);
    }
    setShowAlertsModal(false);
  };

  const loadAlertTemplates = async () => {
    try {
      const alertTemplateList: AlertTemplate[] = await backend.getAlertTemplates();
      const alertTemplates = alertTemplateList.map((alertTemplate) => ({
        id: alertTemplate.id,
        value: alertTemplate.name,
        label: alertTemplate.name,
        subject: alertTemplate.subject,
        message: alertTemplate.message
      }));

      const newAlertTemplate = {
        id: undefined,
        value: "new-template",
        label: "Create a new template",
        subject: "",
        message: ""
      };      
      setTemplates([newAlertTemplate, ...alertTemplates]);
    } catch (error) {
      console.error("Error fetching alertTemplates:", error);
    }
  };

  const addNewTemplate = (
    templateName: string,
    templateSubject: string,
    templateMessage: string
  ) => {
    backend.createAlertTemplate({
      id: undefined,
      name: templateName,
      subject: templateSubject,
      message: templateMessage
    }).then(() => {
      loadAlertTemplates();
    });
    setShowTemplateModal(false);
  };

  const handleEditAlert = (alert: AlertType) => {
    setSelectedAlert(alert);
    openAlertsModal();
  };

  useEffect(() => {
    loadAlertTemplates();
  }, []);

  useEffect(() => {
    const loadAlerts = async () => {
      try {
        const alertList: Alert[] = await backend.getAlerts();
        const alerts = alertList.map((alert) => ({
          id: alert.id,
          templateId: alert.alertTemplateId,
          subject: "",
          message: "",
          answerRequired: alert.actionRequired,
          alertDays: alert.alertDays,
          reminderDays: alert.alertTemplateReminderId,
          recipients: alert.targets,
        }));
        setAlerts(alerts);
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    };
    loadAlerts();
  }, []);

  return (
    <>
      {isOrganisation ? (
        <div className="page">
          <h2>Alerts</h2>
          <div className="alerts-container">
            <div className="alerts-button-container">
              <button onClick={openAlertsModal} className="add-alerts-btn">
                + New alert
              </button>
              <button onClick={openTemplateModal} className="add-alerts-btn">
                + New template
              </button>
            </div>
            <div className="alerts-grid">
              {alerts.map((alert) => (
                <div
                  key={alert.id}
                  className="alert-card"
                  onClick={() => handleEditAlert(alert)}
                >
                  <p>
                    Send alert {alert.alertDays} days before end of contract
                  </p>
                  <p>
                    Send reminder {alert.reminderDays} days before end of
                    contract
                  </p>
                  <p>Recipients: {alert.recipients.map(r => `${r.firstName || ''} ${r.lastName || ''}`).filter(name => name.trim() !== '').join(", ")}</p>
                </div>
              ))}
            </div>
            <Modal isOpen={showAlertsModal} onClose={handleCloseModal}>
              <CreateAlert
                onSave={saveAlert}
                initialData={selectedAlert || null}
                onCancel={handleCloseModal}
                templates={templates}
                addNewTemplate={addNewTemplate}
              />
            </Modal>
            <NewTemplateModal
              isOpen={showTemplateModal}
              onSave={addNewTemplate}
              onClose={handleCloseModal}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Alerts;
