import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Employee from "../api/dto/Employee";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackendHelper from "../api/helpers/BackendHelper";
import ConfirmationModal from "./ConfirmationModal";

type EmployeeDetailModalProps = {
  isOpen: boolean;
  employee: Employee | null;
  onSave: (updatedEmployee: Employee) => void;
  onDelete: (id: number | null) => void;
  onClose: () => void;
};

const EmployeeDetailModal: React.FC<EmployeeDetailModalProps> = ({
  isOpen,
  employee,
  onSave,
  onDelete,
  onClose,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editValues, setEditValues] = useState<Partial<Employee> | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureURL, setProfilePictureURL] = useState<string | null>(
    null
  );
  const [document, setDocument] = useState<File | null>(null);
  const backend = new BackendHelper();

  useEffect(() => {
    if (employee) {
      setEditValues({ ...employee });
      if (employee.profilePictureURL) {
        setProfilePictureURL(employee.profilePictureURL);
      }
    } else {
      setEditValues(null);
    }
    setEditMode(false);
  }, [isOpen, employee]);

  const handleSave = async () => {
    if (editValues && employee) {
      const updatedEmployee: Employee = {
        ...employee,
        ...editValues,
      };

      try {
        if (profilePicture && employee.id) {
          const uploadedProfilePictureURL = await backend.uploadProfilePicture(
            employee.id,
            profilePicture
          );
          updatedEmployee.profilePictureURL = uploadedProfilePictureURL;
        }
        if (document) {
          await backend.uploadDocument(employee.id, document);
        }

        onSave(updatedEmployee);
        onClose();
      } catch (err) {
        console.error("Error uploading files:", err);
      }
    }
  };

  const handleDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const confirmDelete = () => {
    if (employee) {
      onDelete(employee.id);
      onClose();
    }
    setIsConfirmationModalOpen(false);
  };

  const handleInputChange = (field: keyof Employee, value: string) => {
    if (editValues) {
      setEditValues((prevValues) => ({
        ...prevValues,
        [field]: value,
      }));
    }
  };

  const handleDateChange = (field: keyof Employee, date: Date | null) => {
    if (editValues) {
      setEditValues((prevValues) => ({
        ...prevValues,
        [field]: date?.toISOString() || "",
      }));
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      if (name === "profilePicture") {
        setProfilePicture(files[0]);
        setProfilePictureURL(URL.createObjectURL(files[0]));
      } else if (name === "document") {
        setDocument(files[0]);
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        {editValues && (
          <>
            <h2>Employee Details</h2>
            <div className="row-detail-modal">
              <div className="left-column">
                <div className="profile-input">
                  <h5>Profile Picture</h5>
                  <div>
                    {profilePictureURL && (
                      <img
                        src={profilePictureURL}
                        alt="Profile"
                        style={{
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    {editMode ? (
                      <input
                        type="file"
                        name="profilePicture"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    ) : (
                      !profilePictureURL && (
                        <div className="file_input">No file selected</div>
                      )
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Document</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="file"
                        name="document"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                      />
                    ) : (
                      !document && (
                        <div className="file_input">No file selected</div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="profile-input">
                  <h5>First name</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.firstName || ""}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">
                        {editValues.firstName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Last name</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.lastName || ""}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">
                        {editValues.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Email address</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.email || ""}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">{editValues.email}</div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Phone number</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.phone || ""}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">{editValues.phone}</div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Start Date</h5>
                  <div>
                    {editMode ? (
                      <DatePicker
                        className="edit-date-input"
                        selected={
                          editValues.startDate
                            ? new Date(editValues.startDate)
                            : null
                        }
                        onChange={(date) => handleDateChange("startDate", date)}
                        dateFormat="yyyy-MM-dd"
                      />
                    ) : (
                      <div className="date-input">
                        {editValues.startDate &&
                          new Date(editValues.startDate)
                            .toISOString()
                            .slice(0, 10)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>End Date</h5>
                  <div>
                    {editMode ? (
                      <DatePicker
                        className="edit-date-input"
                        selected={
                          editValues.endDate
                            ? new Date(editValues.endDate)
                            : null
                        }
                        onChange={(date) => handleDateChange("endDate", date)}
                        dateFormat="yyyy-MM-dd"
                      />
                    ) : (
                      <div className="date-input">
                        {editValues.endDate &&
                          new Date(editValues.endDate)
                            .toISOString()
                            .slice(0, 10)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="save-cancel-btns">
              {!editMode && (
                <button
                  className="save-cancel-btn"
                  onClick={() => setEditMode(true)}
                >
                  Edit
                </button>
              )}
              {editMode && (
                <>
                  <button className="save-cancel-btn" onClick={handleSave}>
                    Save and close
                  </button>
                  <button
                    className="save-cancel-btn"
                    onClick={() => setEditMode(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
              <button className="save-cancel-btn" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </>
        )}
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmDelete}
        message="Are you sure you want to delete this employee?"
      />
    </>
  );
};

export default EmployeeDetailModal;
