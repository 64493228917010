import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import User from "../api/dto/User";

type AuthContextType = {
  isLoggedIn: boolean;
  isOrganisation: boolean;
  isEmployee: boolean;
  loading: boolean;
  saveAuthToken: (token: string) => void;
  saveUser: (user: User) => void;
  login: (type: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [type, setType] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const saveAuthToken = (token: string):void => {
    localStorage.setItem('access', token);
  }

  const saveUser = (user: User):void => {
    localStorage.setItem('user', JSON.stringify(user));
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || 'null');
    const access = localStorage.getItem('access');
    if (user && user.type) {
      setType(user.type);
    }
    setLoading(false);
  }, []);

  const login = (type: string) => {
    setType(type);
  };

  const logout = () => {
    setType(null);
    localStorage.removeItem("access");
    localStorage.removeItem("user");
  };

  const isLoggedIn = type !== null;
  const isOrganisation = type === "organisation";
  const isEmployee = type === "employee";

  return (
    <AuthContext.Provider value={{ isLoggedIn, isOrganisation, isEmployee, loading, saveAuthToken, saveUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
