import React, { useEffect, useState } from "react";
import BackendHelper from "../api/helpers/BackendHelper";
import NotificationMsg from "../api/dto/NotificationMsg";

const Notifications: React.FC = () => {
  const [items, setItems] = useState<NotificationMsg[]>();
  const backend = new BackendHelper();

  useEffect(() => {
    async function fetchOrganisationData() {
      backend
        .getNotifications(0)
        .then((notifications) => {
          setItems(notifications);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    fetchOrganisationData();
  }, []);

  return (
    <>
        <div className="page">
          <h2>Notifications</h2>
          <table className="notifications-table">
          <thead>
            <tr>
            <th className="t-columns">Created</th>
            <th className="t-columns">Subject</th>
            <th className="t-columns">Message</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item) => (
              <tr key={item.id}>
                <td className="t-columns">{item.created?.toString()}</td>
                <td className="t-columns">{item.subject}</td>
                <td className="t-columns">{item.message.substring(0, 30)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </>
  );

};

export default Notifications;
