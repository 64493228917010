import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../types/AuthContext";

const SidebarNavigation: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn, isOrganisation, isEmployee } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleSelect = (path: string) => {
    navigate("/" + path);
  };

  return (
    <>
      {isLoggedIn ? (
        <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
          <div className="toggle-container">
            <button
              className="toggle-button"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <i
                className={`fa ${
                  isCollapsed ? "fa-chevron-right" : "fa-chevron-left"
                }`}
              />
            </button>
          </div>
          <nav>
            <ul>
              {isOrganisation ? (
                <>
                  <li onClick={() => handleSelect("organisation-profile")}>
                    <i className="fa fa-fw fa-home" />
                    <span className="nav-text">Organisation profile</span>
                  </li>
                  <li onClick={() => handleSelect("employees")}>
                    <i className="fa fa-fw fa-user" />
                    <span className="nav-text">Employees</span>
                  </li>
                  <li onClick={() => handleSelect("alerts")}>
                    <i className="fa fa-fw fa-bell" />
                    <span className="nav-text">Alerts</span>
                  </li>
                </>
              ) : isEmployee ? (
                <>
                  <li onClick={() => handleSelect("employee-profile")}>
                    <i className="fa fa-fw fa-user" />
                    <span className="nav-text">Employee profile</span>
                  </li>
                  <li onClick={() => handleSelect("notifications")}>
                    <i className="fa fa-fw fa-bell" />
                    <span className="nav-text">Notifications</span>
                  </li>
                </>
              ) : null}
              <div className="sidebar-divider" />
              <li onClick={() => handleSelect("logout")}>
                <i className="fa fa-fw fa-sign-out-alt" />
                <span className="nav-text">Logout</span>
              </li>
            </ul>
          </nav>
        </div>
      ) : null}
    </>
  );
};

export default SidebarNavigation;
