import React, { useEffect, useState } from "react";
import { useAuth } from "../types/AuthContext";
import BackendHelper from "../api/helpers/BackendHelper";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "../components/ConfirmationModal";

const EmployeeProfile: React.FC = () => {
  const [employee, setEmployee] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    startDate: "",
    endDate: "",
  });
  const [editValues, setEditValues] = useState<any>({ ...employee });
  const [user, setUser] = useState<any>(null);
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { isEmployee } = useAuth();
  const backend = new BackendHelper();

  const [editMode, setEditMode] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureURL, setProfilePictureURL] = useState<string | null>(
    null
  );
  const [document, setDocument] = useState<File | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    async function fetchEmployeeData() {
      if (user && user.employeeId) {
        try {
          const emp = await backend.getEmployee(user.employeeId);
          setEmployee(emp);
          setEditValues(emp);
          if (emp.profilePictureURL) {
            setProfilePictureURL(emp.profilePictureURL);
          }
        } catch (e) {
          setError(error);
        }
      }
    }
    fetchEmployeeData();
  }, [user]);

  const handleInputChange = (field: keyof typeof employee, value: string) => {
    setChanged(true);
    setEditValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      if (name === "profilePicture") {
        setProfilePicture(files[0]);
        setProfilePictureURL(URL.createObjectURL(files[0]));
      } else if (name === "document") {
        setDocument(files[0]);
      }
    }
  };

  const handleSave = async (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    if (!user || !user.employeeId) {
      setError("User ID is missing.");
      return;
    }

    const updatedEmployee = { ...editValues };
    try {
      if (profilePicture) {
        const uploadedProfilePictureURL = await backend.uploadProfilePicture(
          user.personId,
          profilePicture
        );
        updatedEmployee.profilePictureURL = uploadedProfilePictureURL;
      }
      if (document) {
        await backend.uploadDocument(user.personId, document);
      }

      await backend.updateEmployee(updatedEmployee);
      setEmployee(updatedEmployee);
      setChanged(false);
      setEditMode(false);
      setError(undefined);
    } catch (e) {
      setError(error);
    }
  };

  const handleDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const confirmDelete = async () => {
    if (user && user.personId) {
      try {
        await backend.deleteEmployee(user.personId);
      } catch (err) {
        setError(error);
      }
    }
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      {isEmployee ? (
        <div className="page">
          <h2>Welcome, {employee.firstName}!</h2>
          <form
            className="employee-profile-form"
            id="employee"
            onSubmit={handleSave}
          >
            <div className="row-employee-details">
              <div className="left-column">
                <div className="profile-input">
                  <h5>Profile Picture</h5>
                  <div>
                    {profilePictureURL && (
                      <img
                        src={profilePictureURL}
                        alt="Profile"
                        style={{ marginBottom: "10px" }}
                      />
                    )}
                    {editMode ? (
                      <input
                        type="file"
                        name="profilePicture"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    ) : (
                      !profilePictureURL && (
                        <div className="file_input">
                          No profile picture uploaded
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Document</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="file"
                        name="document"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                      />
                    ) : (
                      !document && (
                        <div className="file_input">No file uploaded</div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="profile-input">
                  <h5>First name</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.firstName || ""}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">
                        {editValues.firstName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Last name</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.lastName || ""}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">
                        {editValues.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Email address</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.email || ""}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">{editValues.email}</div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Phone number</h5>
                  <div>
                    {editMode ? (
                      <input
                        type="text"
                        className="edit-text-input"
                        value={editValues.phone || ""}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                      />
                    ) : (
                      <div className="modal-text-input">{editValues.phone}</div>
                    )}
                  </div>
                </div>
                <div className="profile-input">
                  <h5>Start Date</h5>
                  <div>
                    <div className="date-input">
                      {editValues.startDate &&
                        new Date(editValues.startDate)
                          .toISOString()
                          .slice(0, 10)}
                    </div>
                  </div>
                </div>
                <div className="profile-input">
                  <h5>End Date</h5>
                  <div>
                    <div className="date-input">
                      {editValues.endDate &&
                        new Date(editValues.endDate).toISOString().slice(0, 10)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
};

export default EmployeeProfile;
