import React, { useEffect, useState } from "react";
import CrudTable from "../components/EmployeeList";
import Employee from "../api/dto/Employee";
import BackendHelper from "../api/helpers/BackendHelper";
import { useAuth } from "../types/AuthContext";

const Employees: React.FC = () => {
  const [items, setItems] = useState<Employee[]>([]);
  const backend = new BackendHelper();
  const { isOrganisation } = useAuth();

  useEffect(() => {
    const fetchItems = async () => {
      const data = await backend.getEmployees();
      setItems(data);
    };
    fetchItems();
  }, []);

  return (
    <>
      {isOrganisation ? (
        <div className="page">
          <CrudTable items={items} setItems={setItems} />
        </div>
      ) : null}
    </>
  );
};

export default Employees;
