import React, { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../types/AuthContext";

const AuthError: React.FC = () => {
  const { isLoggedIn, isOrganisation, isEmployee } = useAuth();
  const location = useLocation();

  return (
    <div className="page">
      <h2>Authentication Error</h2>
      <div className="auth-error-content">
        {isLoggedIn ? (
          isOrganisation ? (
            <p>You don't have permission to access this page. This content is for employees only.</p>
          ) : isEmployee ? (
            <p>You don't have permission to access this page. This content is for organisations only.</p>
          ) : (
            <p>You don't have permission to access this page.</p>
          )
        ) : (
          <p>You need to be logged in to access this page.</p>
        )}
        {!isLoggedIn && (
          <Link to="/login" state={{ from: location }}>
            <input
              type="submit"
              value="Log In"
              className="subcat-btn auth-error-btn"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default AuthError;