import React, { useState } from "react";
import Employee from "../api/dto/Employee";
import Modal from "./Modal";
import Select from "react-select";
import ConfirmationPopup from "./ConfirmationPopup";

type MessagingModalProps = {
  isOpen: boolean;
  employees: Employee[];
  onClose: () => void;
  onSend: (recipientIds: number[], subject: string, message: string) => void;
  preFilledMessage?: string;
};

const MessagingModal: React.FC<MessagingModalProps> = ({
  isOpen,
  employees,
  onClose,
  onSend,
  preFilledMessage = "",
}) => {
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(preFilledMessage);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleSend = () => {
    if (selectedEmployees.length > 0 && subject && message) {
      const recipientIds = selectedEmployees.map((employee) => employee.id!);
      onSend(recipientIds, subject, message);
      onClose();
      setShowConfirmationPopup(true);
    } else {
      setError("Please fill out all fields");
    }
  };

  const employeeOptions = employees.map((employee) => ({
    value: employee.id!,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const handleEmployeeChange = (selectedOptions: any) => {
    setSelectedEmployees(
      selectedOptions.map(
        (option: any) => employees.find((e: Employee) => e.id === option.value)!
      )
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="modal-content">
          <h2>Send Message</h2>
          <div className="profile-input">
            <Select
              id="employee"
              isMulti
              options={employeeOptions}
              onChange={handleEmployeeChange}
              value={selectedEmployees.map((employee) => ({
                value: employee.id!,
                label: `${employee.firstName} ${employee.lastName}`,
              }))}
              className="employee-selector"
              placeholder="Select recipient(s)"
            />
          </div>
          <div className="profile-input">
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="modal-input"
              placeholder="Subject"
            />
          </div>
          <div className="profile-input">
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="modal-textarea"
              placeholder="Message"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="save-cancel-btns">
            <button onClick={handleSend} className="save-cancel-btn">
              Send
            </button>
            <button onClick={onClose} className="save-cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {showConfirmationPopup && (
        <ConfirmationPopup
          message="Message sent successfully!"
          onClose={() => setShowConfirmationPopup(false)}
        />
      )}
    </>
  );
};

export default MessagingModal;
