import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../types/AuthContext";

const Logout: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function doLogout() {
      logout();
    }

    doLogout();
    navigate("/login");
  });

  return (
    <>
      <div />
    </>
  );
};

export default Logout;
