import React, { useEffect, useState } from "react";
import { useAuth } from "../types/AuthContext";
import BackendHelper from "../api/helpers/BackendHelper";

const OrgProfile: React.FC = () => {
  const [organisation, setOrganisation] = useState<any>({
    name: "",
    email: "",
    orgNumber: "",
    phone: "",
  });
  const [tempOrganisation, setTempOrganisation] = useState<any>({
    name: "",
    email: "",
    orgNumber: "",
    phone: "",
  });
  const [address, setAddress] = useState<any>({
    address1: "",
    address2: "",
    postCode: "",
    municipality: "",
    city: "",
    country: "",
  });
  const [tempAddress, setTempAddress] = useState<any>({
    address1: "",
    address2: "",
    postCode: "",
    municipality: "",
    city: "",
    country: "",
  });

  const [changed, setChanged] = useState(false);
  const [error, setError] = useState();
  const { isOrganisation } = useAuth();
  const backend = new BackendHelper();

  useEffect(() => {
    async function fetchOrganisationData() {
      backend
        .getOrganisation()
        .then((org) => {
          setOrganisation(org);
          setTempOrganisation(org);
        })
        .catch((e) => {
          setError(error);
        });

      backend
        .getOrganisationAddress()
        .then((addr) => {
          setAddress(addr);
          setTempAddress(addr);
        })
        .catch((e) => {
          setError(error);
        });
    }
    fetchOrganisationData();
  }, []);

  function saveNewOrganisation(e: React.ChangeEvent<any>) {
    e.preventDefault();
    setOrganisation(tempOrganisation);
    setAddress(tempAddress);

    backend
      .updateOrganisation(tempOrganisation)
      .then((res) => {
        setError(undefined);
      })
      .catch((e) => {
        setError(e.message);
      });

    backend
      .updateOrganisationAddress(tempAddress)
      .then((res) => {
        setError(undefined);
      })
      .catch((e) => {
        setError(e.message);
      });

    setChanged(false);
  }

  return (
    <>
      {isOrganisation ? (
        <div className="page">
          <h2>Organisation profile</h2>
          <form
            className="org-profile-form"
            id="organisation"
            onSubmit={saveNewOrganisation}
          >
            <div className="profile-input">
              <h5>Company name</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempOrganisation.name}
                  onChange={(e) => {
                    setChanged(true);
                    setTempOrganisation({
                      ...tempOrganisation,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Email address</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempOrganisation.email}
                  onChange={(e) => {
                    setChanged(true);
                    setTempOrganisation({
                      ...tempOrganisation,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Organisation number</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempOrganisation.orgNumber}
                  onChange={(e) => {
                    setChanged(true);
                    setTempOrganisation({
                      ...tempOrganisation,
                      orgNumber: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Phone number</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempOrganisation.phone}
                  onChange={(e) => {
                    setChanged(true);
                    setTempOrganisation({
                      ...tempOrganisation,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Address line 1</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.address1}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      address1: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Address line 2</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.address2}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      address2: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Postcode</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.postCode}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      postCode: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>City</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.city}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      city: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Municipality</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.municipality}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      municipality: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="profile-input">
              <h5>Country</h5>
              <div className="text-area">
                <input
                  className="text-input"
                  value={tempAddress.country}
                  onChange={(e) => {
                    setChanged(true);
                    setTempAddress({
                      ...tempAddress,
                      country: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {changed && isOrganisation ? (
              <div className="save-cancel-btns">
                <button form="organisation" className="save-cancel-btn">
                  Save
                </button>
                <button
                  className="save-cancel-btn"
                  onClick={() => {
                    setTempOrganisation({ ...organisation });
                    setTempAddress({ ...address });
                    setChanged(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </form>
        </div>
      ) : null}
    </>
  );
};

export default OrgProfile;
