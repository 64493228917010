import React, { useState } from "react";
import BackendHelper from "../api/helpers/BackendHelper";

const RegisterOrganisation: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const backend = new BackendHelper();

  const handleRegister = async (e: any) => {
    e.preventDefault();
    setSuccess(null);
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      passwordRepeat === ""
    ) {
      setError("All fields are required");
      return;
    }
    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    if (!regex.test(email)) {
      setError("Enter valid email");
      return;
    }
    if (password !== passwordRepeat) {
      setError("Password values do not match");
      return;
    }

    await backend
      .registerOrganisation({
        id: null,
        email: email,
        password: password,
        name: name,
        orgNumber: null,
        phone: null,
      })
      .then((data) => {
        setSuccess("Organisation is registered");
        setError(null);
      })
      .catch((e) => {
        setError("Registration failed: " + e.message);
      });
  };

  return (
    <>
      <div className="login">
        <img
          src={"./initiator-logo.jpeg"}
          alt=""
          width="150"
          height="150"
          className="register-img"
        />
        <h4>Register new organisation</h4>
        <form>
          <h5>Organisation name</h5>
          <div className="text-area">
            <input
              type="org-name"
              id="org-name"
              name="org-name"
              className="text-input"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <h5>Email address</h5>
          <div className="text-area">
            <input
              type="email"
              id="email"
              name="email"
              className="text-input"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <h5>Password</h5>
          <div className="text-area">
            <input
              type="password"
              id="password"
              name="password"
              className="text-input"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <h5>Confirm password</h5>
          <div className="text-area">
            <input
              type="password"
              id="passwordRepeat"
              name="passwordRepeat"
              className="text-input"
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
          {success && <p>{success}</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="regorg-button-container">
            <input
              type="submit"
              value="REGISTER"
              className="register-btn"
              onClick={handleRegister}
            />
            <a href="/login">Back to log in</a>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterOrganisation;
