import React, { useState, useMemo } from "react";
import Employee from "../api/dto/Employee";
import BackendHelper from "../api/helpers/BackendHelper";
import Modal from "./Modal";
import RegisterEmployee from "./RegisterEmployee";
import EmployeeDetailModal from "./EmployeeDetailModal";
import MessagingModal from "./MessagingModal";
import "react-datepicker/dist/react-datepicker.css";

type EmployeeListProps = {
  items: Employee[];
  setItems: React.Dispatch<React.SetStateAction<Employee[]>>;
};

const EmployeeList: React.FC<EmployeeListProps> = ({ items, setItems }) => {
  const backend = new BackendHelper();
  const [error, setError] = useState<string | null>(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Employee | any;
    direction: "asc" | "desc";
  } | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [showMessagingModal, setShowMessagingModal] = useState(false);

  const handleDeleteEmployee = async (id: any) => {
    setError(null);
    try {
      await backend.deleteEmployee(id);
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
      setShowDetailModal(false);
    } catch (err) {
      console.error("Error deleting employee:", err);
      setError("Failed to delete employee.");
    }
  };

  const handleSaveEmployee = async (updatedEmployee: Employee) => {
    try {
      await backend.updateEmployee(updatedEmployee);
      const updatedItems = items.map((item) =>
        item.id === updatedEmployee.id ? updatedEmployee : item
      );
      setItems(updatedItems);
      setShowDetailModal(false);
    } catch (err) {
      console.error("Error updating employee:", err);
    }
  };

  const handleRowClick = (employee: Employee) => {
    setSelectedEmployee(employee);
    setShowDetailModal(true);
  };

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const handleCloseModal = () => {
    setShowRegisterModal(false);
  };

  const openMessagingModal = () => {
    setShowMessagingModal(true);
  };

  const handleCloseMessagingModal = () => {
    setShowMessagingModal(false);
  };

  const handleSendMessage = async (
    recipientIds: number[],
    subject: string,
    message: string
  ) => {
    for (var recipientId of recipientIds) {
      try {
        await backend.sendNotification(recipientId, {
            id: undefined,
            subject: subject, 
            message: message,
            created: null
          });
      } catch (err) {
        console.error("Error sending message:", err);
      }
    }
    setShowMessagingModal(false);
  };

  const requestSort = (key: keyof Employee) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const getSortIcon = (key: keyof Employee) => {
    if (!sortConfig) {
      return null;
    }
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return null;
  };

  return (
    <div>
      <h2>Employee list</h2>
      <div className="crud-box">
        <div className="add-employee-btn-container">
          <button onClick={openRegisterModal} className="add-employee-btn">
            + New employee
          </button>
          <button onClick={openMessagingModal} className="add-employee-btn">
            Send message
          </button>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <table className="crud-table">
          <thead>
            <tr>
              <th className="t-columns" onClick={() => requestSort("id")}>
                ID {getSortIcon("id")}
              </th>
              <th
                className="t-columns"
                onClick={() => requestSort("firstName")}
              >
                First name {getSortIcon("firstName")}
              </th>
              <th className="t-columns" onClick={() => requestSort("lastName")}>
                Last name {getSortIcon("lastName")}
              </th>
              <th className="t-columns" onClick={() => requestSort("email")}>
                Email address {getSortIcon("email")}
              </th>
              <th className="t-columns" onClick={() => requestSort("phone")}>
                Phone number {getSortIcon("phone")}
              </th>
              <th
                className="t-columns"
                onClick={() => requestSort("startDate")}
              >
                Start date {getSortIcon("startDate")}
              </th>
              <th className="t-columns" onClick={() => requestSort("endDate")}>
                End date {getSortIcon("endDate")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedItems.map((item) => (
              <tr key={item.id} onClick={() => handleRowClick(item)}>
                <td className="t-columns">{item.id}</td>
                <td className="t-columns">{item.firstName}</td>
                <td className="t-columns">{item.lastName}</td>
                <td className="t-columns">{item.email}</td>
                <td className="t-columns">{item.phone}</td>
                <td className="t-columns">
                  {new Date(item.startDate).toISOString().slice(0, 10)}
                </td>
                <td className="t-columns">
                  {item.endDate
                    ? new Date(item.endDate).toISOString().slice(0, 10)
                    : "-"}
                </td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
        <EmployeeDetailModal
          isOpen={showDetailModal}
          employee={selectedEmployee}
          onSave={handleSaveEmployee}
          onDelete={handleDeleteEmployee}
          onClose={() => setShowDetailModal(false)}
        />
        <MessagingModal
          isOpen={showMessagingModal}
          employees={items}
          onClose={handleCloseMessagingModal}
          onSend={handleSendMessage}
          preFilledMessage=""
        />
        <Modal isOpen={showRegisterModal} onClose={handleCloseModal}>
          <RegisterEmployee setItems={setItems} onClose={handleCloseModal} />
        </Modal>
      </div>
    </div>
  );
};

export default EmployeeList;
