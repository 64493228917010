import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./types/AuthContext";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import RegisterOrganisation from "./pages/RegOrg";
import { AuthProvider } from "./types/AuthContext";
import SidebarNavigation from "./components/SidebarNavigation";
import Employees from "./pages/Employees";
import OrgProfile from "./pages/OrgProfile";
import Alerts from "./pages/Alerts";
import EmployeeProfile from "./pages/EmployeeProfile";
import Notifications from "./pages/Notifications";
import AuthError from "./pages/AuthError";
import ProtectedRoute, { RouteType } from "./components/ProtectedRoute";

const App: React.FC = () => {
  return (
      <div id="main">
        <AuthProvider>
          <SidebarNavigation />
          <div className="page-container">
            <Routes>
              {/* Public Route Paths */}
              <Route path="/" element={<Login/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/logout" element={<Logout/>}/>
              <Route
                  path="/register-organisation"
                  element={<RegisterOrganisation/>}
              />
              <Route path="/auth-error" element={<AuthError/>}/>

              {/* Private Organisation Route Paths */}
              <Route path="/organisation-profile" element={<ProtectedRoute element={<OrgProfile/>} routeType={RouteType.Organisation}/>}/>
              <Route path="/employees" element={<ProtectedRoute element={<Employees/>} routeType={RouteType.Organisation}/>}/>
              <Route path="/alerts" element={<ProtectedRoute element={<Alerts/>} routeType={RouteType.Organisation}/>}/>

              {/* Private Employee Route Paths */}
              <Route path="/employee-profile" element={<ProtectedRoute element={<EmployeeProfile/>} routeType={RouteType.Employee}/>}/>
              <Route path="/notifications" element={<ProtectedRoute element={<Notifications/>} routeType={RouteType.Employee}/>}/>
            </Routes>
          </div>
        </AuthProvider>
      </div>
  );
};

export default App;
