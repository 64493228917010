import React, { useState } from "react";
import BackendHelper from "../api/helpers/BackendHelper";
import Employee from "../api/dto/Employee";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type RegisterEmployeeProps = {
  setItems: React.Dispatch<React.SetStateAction<Employee[]>>;
  onClose: () => void;
};

const RegisterEmployee: React.FC<RegisterEmployeeProps> = ({
  setItems,
  onClose,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const backend = new BackendHelper();

  const handleRegisterEmployee = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setError(null);

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      phoneNumber === "" ||
      password === "" ||
      passwordRepeat === "" ||
      !startDate ||
      !endDate
    ) {
      setError("All fields are required");
      return;
    }

    const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
    if (!regex.test(email)) {
      setError("Enter valid email");
      return;
    }

    if (password !== passwordRepeat) {
      setError("Password values do not match");
      return;
    }

    setLoading(true);

    try {
      const newEmployee = await backend.createEmployee({
        id: null,
        email,
        password,
        firstName,
        lastName,
        phone: phoneNumber,
        startDate,
        endDate,
      });

      setItems((prevItems) => [...prevItems, newEmployee]);
      onClose();
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setStartDate(new Date());
      setEndDate(new Date());
      setPassword("");
      setPasswordRepeat("");
      setError(null);
    } catch (e: any) {
      setError("Registration failed: " + e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-new-employee-profile">
      <h2>Register new employee</h2>
      <form
        className="register-new-employee-form"
        onSubmit={handleRegisterEmployee}
      >
        <div>
          <h5>First name</h5>
          <div className="text-area">
            <input
              type="text"
              id="first-name"
              name="first-name"
              className="text-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Last name</h5>
          <div className="text-area">
            <input
              type="text"
              id="last-name"
              name="last-name"
              className="text-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Email address</h5>
          <div className="text-area">
            <input
              type="email"
              id="email"
              name="email"
              className="text-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Phone number</h5>
          <div className="text-area">
            <input
              type="text"
              id="phone-number"
              name="phone-number"
              className="text-input"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Start Date</h5>
          <div className="text-area">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              className="date-input"
            />
          </div>
        </div>
        <div>
          <h5>End Date</h5>
          <div className="text-area">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              className="date-input"
            />
          </div>
        </div>
        <div>
          <h5>Password</h5>
          <div className="text-area">
            <input
              type="password"
              id="password"
              name="password"
              className="text-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Repeat password</h5>
          <div className="text-area">
            <input
              type="password"
              id="password-repeat"
              name="password-repeat"
              className="text-input"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <input
          type="submit"
          value="Register"
          className="register-btn"
          disabled={loading}
        />
      </form>
    </div>
  );
};

export default RegisterEmployee;
