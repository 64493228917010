import React from "react";
import Modal from "./Modal";

type ConfirmationModalProps = {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">
            <h4>Confirmation</h4>
            <h6>{message}</h6>
            <div className="save-cancel-btns">
              <button className="save-cancel-btn" onClick={onConfirm}>
                Yes
              </button>
              <button className="save-cancel-btn" onClick={onCancel}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      );
    </Modal>
  );
};

export default ConfirmationModal;
