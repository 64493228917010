import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../types/AuthContext";

enum RouteType {
  Organisation = "organisation",
  Employee = "employee",
  Both = "both"
}

interface ProtectedRouteProps {
  element: React.ReactElement;
  routeType: RouteType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, routeType }) => {
  const { isLoggedIn, isOrganisation, isEmployee, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <div>Loading...</div>;
  }

  const hasAccess = 
    isLoggedIn && 
    (routeType === RouteType.Both || 
     (routeType === RouteType.Organisation && isOrganisation) || 
     (routeType === RouteType.Employee && isEmployee));

  if (!hasAccess) {
    return <Navigate to="/auth-error" state={{ from: location }} replace />;
  }
  
  return element;
};

export default ProtectedRoute;
export { RouteType };