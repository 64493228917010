import React, { useState } from "react";
import Modal from "./Modal";

type NewTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    templateName: string,
    templateSubject: string,
    templateMessage: string
  ) => void;
};

const NewTemplateModal: React.FC<NewTemplateModalProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateMessage, setTemplateMessage] = useState("");

  const handleSave = () => {
    if (templateName && templateSubject && templateMessage) {
      onSave(templateName, templateSubject, templateMessage);
      setTemplateName("");
      setTemplateSubject("");
      setTemplateMessage("");
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal-content">
        <h2>Create new template</h2>
        <div className="profile-input">
          <h5>Template name</h5>
          <input
            type="text"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className="modal-input"
          />
        </div>
        <div className="profile-input">
          <h5>Subject</h5>
          <input
            type="text"
            value={templateSubject}
            onChange={(e) => setTemplateSubject(e.target.value)}
            className="modal-input"
          />
        </div>
        <div className="profile-input">
          <h5>Message</h5>
          <textarea
            value={templateMessage}
            onChange={(e) => setTemplateMessage(e.target.value)}
            className="modal-textarea"
          />
        </div>
        <div className="save-cancel-btns">
          <button onClick={handleSave} className="save-cancel-btn">
            Save
          </button>
          <button onClick={onClose} className="save-cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewTemplateModal;
